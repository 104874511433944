import Seperator from '../Seperator'

const Ryouga = () => {
    return (
        <div>
            <Seperator quote="Where the heck am I now?" />
            <section>
                <h2 className="subhead">Ryouga Hibiki</h2>
                <article>
                    <div className="col">
                        <img src="https://douglasave-static.sfo3.digitaloceanspaces.com/funkdafied-static/img/ryouga.jpg" alt="Ryouga and all his little ticks" 
                        title="Ryouga and all his little ticks" className="left mob-sizing" />
                    </div>
                    <div className="col">
                        <p>Holy Hannah. I hate Ryouga. Well, I guess hate is kind of a strong word. I guess it would be more like I REALLY, REALLY, don't 
                        like Ryouga.</p>
                        <p>I think I do not like him because of the way he acts. He does not seem to want to take responsibility for his own actions. A little 
                        background here.</p>
                        <p>Before Genma drug Ranma off to China, Ryouga challenged him. (Ranma, not Genma.) Well, Ryouga, who has NO sense of direction got lost trying to find the vacant 
                        lot they were supposed to meet. This vacant lot was right BEHIND Ryouga's house. Ranma waited for Ryouga for 3 days. Ryouga arrived on the fourth day, after 
                        Ranma had left. So Ryouga in his infinite wisdom chases after Ranma to China. He ends up at a cliff overlooking Jusyenko. Ranma, in female form, sprints by 
                        knocking him into Spring of Drowned Piglet. You may think that this is Ranma's fault, but if that moron had stayed in Japan, well.</p>
                        <p>Now, Ryouga is hell bent on revenge. But to make things more interesting Ryouga's cursed form is Akane's pet. To top things off Ryouga is in love with Akane! 
                        Sounds like a funked-up version of The 5th Wheel, eh? (The 5th Wheel was a stupid reality show that was airing when I first wrote this…)</p>
                    </div>
                </article>
                <hr className="sep-hr" />
                <div className="col">
                    <h3 className="article-head">Ryouga's Corner</h3>
                    <p>I'm Ryouga Hibiki. Age 16.</p>
                    <p>I have been cursed to spend the rest of my life as a pig thanks to that damned Ranma! <em>(Oh God, here we go.)</em> SHUT UP ERIN. Because of him I have been 
                    to hell and back! When I first became cursed his father tried to cook me! Then, the girl he is cheating on Akane with, Shampoo tried to cook me! Why can't 
                    they just become vegetarians?</p>
                    <p>Yes, it's true I love Akane. Can you think of a reason why I shouldn't? <em>(I can think of about fifty reasons.)</em> Didn't I tell you to shut up? <em>(Sorry.)</em> Okay then. 
                    Yes, it's true that I spend my nights as Akane's pet, but I don't like it! *Erin's laughing cause him to have to leave the room* What's his problem? Anyway, 
                    I don't! I want to be able to love her as a man. I just - just haven't gotten around to telling her. Why would she want someone like me? And I can just 
                    imagine what would happen if she found out about my secret! She would hate me even more!</p>
                    <p>I do have a slight problem with getting lost, but it is good for me. It helps in my training so that I can beat Ranma, avenge my curse, and give Akane a 
                    fiancée who will love her and treat her right. <em>(And I suppose that's your story and you’re sticking with it?)</em> Yep.</p>
                </div>
            </section>
        </div>
    )
}

export default Ryouga
